#servicos{
    background-color: #f3f3f3;
    padding-block: 110px;
}

#servicos h1{
    color:darkcyan;
    font-weight: 380;
}
#servicos h4{
    color:rgb(241, 166, 26);
 
}

#servicos img{
    max-height: 420px;
}
.serv{
    display: flex;
    justify-content:space-between;
}

.botao{
   width: 157px;
   min-height: 40px;
}
.botaoP{
    display: flex;
    justify-content:end;
 }
 .ju{
  background-color: darkcyan;
  color: #fff;
 }
 
 

   
